import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterOutlet, provideRouter } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { environment as env, environment } from '../environments/environment';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideAnimations(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        importProvidersFrom(
            HttpClientModule,
            RouterOutlet,
            AuthModule.forRoot({
                ...env.auth0,
                httpInterceptor: {
                    allowedList: ['*']
                },
                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: environment.auth0Audience
                }
            })
        ),
        provideAnimationsAsync(),
        provideCharts(withDefaultRegisterables())
    ]
};
