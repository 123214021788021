import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { paymentReceiverResolver } from './components/payment-receiver/payment-receiver.resolver';
import { projectsResolver } from './components/projects/projects.resolver';
import { subscriptionResolver } from './components/subscription/subscription.resolver';

export const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'spenden',
                pathMatch: 'full'
            },
            {
                path: 'spenden',
                loadComponent: () => import('./components/donation/donation.component').then((x) => x.DonationComponent)
            },
            {
                path: 'abos',
                loadComponent: () => import('./components/subscription/subscription.component').then((x) => x.SubscriptionComponent),
                resolve: { subscriptions: subscriptionResolver }
            },
            {
                path: 'tiere',
                loadComponent: () => import('./components/payment-receiver/payment-receiver.component').then((x) => x.PaymentReceiverComponent),
                resolve: { paymentReceivers: paymentReceiverResolver }
            },
            {
                path: 'umfragen',
                loadChildren: () => import('./components/survey/survey.routing').then((x) => x.SURVEY_ROUTES)
            },
            {
                path: 'projekte',
                loadComponent: () => import('./components/projects/projects.component').then((x) => x.ProjectsComponent),
                resolve: { projects: projectsResolver }
            }
        ]
    }
];
