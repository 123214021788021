import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription, SubscriptionService } from './subscription.service';

export const subscriptionResolver: ResolveFn<Observable<Subscription[]>> = (route, state) => {
    const subscriptionService = inject(SubscriptionService);

    return subscriptionService.getSubscriptions();
};
