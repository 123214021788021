import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    private http = inject(HttpClient);

    getSubscriptions(): Observable<Subscription[]> {
        return this.http.get<Subscription[]>(environment.backend.baseUrl + '/donations/subscriptions');
    }
}

export interface Subscription {
    amount: number;
    customerId: string;
    fullName: string;
}
