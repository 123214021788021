<div *ngIf="isAuthenticated$ | async; else loadingView">
    <mat-drawer-container hasBackdrop="false" class="w-100">
        <mat-drawer #drawer mode="side" opened>
            <div class="py-5 px-4">
                <div class="row">
                    <div class="col-12 text-center">
                        <a routerLink="">
                            <img src="./assets/images/logo.svg" alt="" style="width: 100px" />
                        </a>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <ul class="list-unstyled">
                            <ng-container *ngFor="let menuItem of menuItems">
                                <li class="mb-3">
                                    <a [routerLink]="menuItem.path" routerLinkActive="active" #menuButton="routerLinkActive" class="menu-link">
                                        <img [src]="'/assets/images/' + menuItem.icon + (menuButton.isActive ? '-black.svg' : '.svg')" alt="" class="icon-small me-3" />
                                        {{ menuItem.title }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content class="background-green">
            @if (environment?.name === 'DEV' && !environment.production) {
                <app-topbar [environmentName]="environment?.name"></app-topbar>
            }
            <app-navigation (toggleSidebar)="drawer.toggle()"></app-navigation>
            <div class="container-fluid px-3 px-lg-5 py-5">
                <div class="row">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<ng-template #loadingView>
    <app-loader></app-loader>
</ng-template>
