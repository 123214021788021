<div class="container-fluid px-3 px-lg-5 pt-4">
    <div class="row">
        <div class="col">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <button class="btn-unstyle" (click)="toggleSidebar.emit()">
                        <img src="../../../../assets/images/icon-hamburger.svg" alt="" class="icon-small" />
                    </button>
                </li>
            </ul>
        </div>
        <div class="col-auto">
            <app-logged-in-user></app-logged-in-user>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h1>{{ pageHeadline() }}</h1>
        </div>
    </div>
</div>
