import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-logged-in-user',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatMenuModule],
    templateUrl: './logged-in-user.component.html',
    styleUrl: './logged-in-user.component.scss'
})
export class LoggedInUserComponent {
    protected auth = inject(AuthService);

    protected readonly document = document;
}
