import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    constructor(private http: HttpClient) {}

    getProjects(): Observable<Project[]> {
        return this.http.get<Project[]>(environment.backend.baseUrl + '/projects');
    }

    updateProject(id: string, project: UpdatableProject): Observable<Project> {
        return this.http.put<Project>(environment.backend.baseUrl + '/projects/' + id, project);
    }

    getTemplate(templateId: string): Observable<Blob> {
        return this.http.get(environment.backend.baseUrl + '/patenschaft/' + templateId, {
            responseType: 'blob'
        });
    }
}

export interface UpdatableProject {
    type: string;
    name: string;
    productImage: string;
}

export interface Project {
    id: string;
    name: string;
    productImage: string;
    type: string;
    createdAt: string;
    updatedAt: boolean;
    templates: Template[];
}

export interface Template {
    id: string;
    productId: string;
}
