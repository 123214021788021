import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LogoutButtonComponent } from '../buttons/logout-button.component';
import { LoggedInUserComponent } from '../logged-in-user/logged-in-user.component';
import { NavigationService } from './navigation.service';

@Component({
    selector: 'app-navigation',
    standalone: true,
    imports: [CommonModule, LogoutButtonComponent, RouterLink, RouterLinkActive, LoggedInUserComponent],
    templateUrl: './navigation.component.html',
    styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
    auth = inject(AuthService);
    private navigationService = inject(NavigationService);
    pageHeadline = this.navigationService.pageHeadline;
    protected readonly document = document;

    @Output() toggleSidebar: EventEmitter<any> = new EventEmitter();
}
